import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

import Accordion from 'react-bootstrap/Accordion';
import { Row, Col, Container } from 'react-bootstrap';

import * as yup from 'yup';

import Login from './Login';

import { onAuthStateChanged } from "firebase/auth";
import { auth } from '../firebaseConfig/firebase';
import { signOut } from "firebase/auth";
import { doc, setDoc, getDocs, collection, where } from 'firebase/firestore';
import { db } from '../firebaseConfig/firebase';

import logo from '../images/logo_escac_black2.png';
import logoMin from '../images/logo_escac_black.png';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import QuizzProfe from './QuizzProfe';
import Loading from './Loading';

function Resultats() {


    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);


    const [lista1, setLista] = useState([]);
    const [preguntes, setPreguntes] = useState([]);

    const [curs01, setCurs01] = useState();
    const [curs02, setCurs02] = useState();
    const [curs03, setCurs03] = useState();
    const [grau, setGrau] = useState();

    const [valido, setValido] = useState();
    const [admin, setAdmin] = useState();
    const [admin2, setAdmin2] = useState();
    const [email, setEmail] = useState();
    const [nombre, setNombre] = useState();

    const schema = yup.object().shape({
        nombre: yup.string().required().min(6,),

    });


    const enviar = async (values) => {

        var date = new Date().getTime();
        var date = date.toString();

        try {
            await setDoc(doc(db, 'QuizzRes', date), {
                ...values
            })
            console.log("Sended");


        } catch (error) {
            console.log(error);
        }
    }



    const handleLogout = () => {
        signOut(auth).then(() => {

            // Sign-out successful.
            navigate("/");
            console.log("Signed out successfully")
        }).catch((error) => {
            // An error happened.
        });
    }

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            setLoading(true);
            setTimeout(() => {
                setLoading(false)
            }, 1000)
            if (user) {
                const uid = user.uid;
                setEmail(user.email);
                setNombre(user.displayName);
                if (user.email === "oleguer.palet@escac.es") {
                    setAdmin2(true);
                }
                if (user.email === "jaume.macia@escac.es") {
                    setAdmin2(true);
                }
                if (user.email === "dalal.battikh@escac.es") {
                    setAdmin2(true);
                }
                setValido(true);

            } else {
                setValido(false);
                console.log("user is logged out")
                navigate("/login")

            }
        });

    }, [])

    useEffect(() => {
        const getLista = async () => {
            try {
                const querySnapshot = await getDocs(collection(db, 'QuizzRes'))
                const docs = []

                querySnapshot.forEach((doc) => {
                    const body = JSON.parse(doc.data().nombre)
                    docs.unshift(body)

                })
                //console.log(docs)
                setLista(docs);


            } catch (error) {
                console.log(error);
            }
        }
        getLista()
    }, [lista1]);

    useEffect(() => {
        const getLista = async () => {
            try {
                const querySnapshot = await getDocs(collection(db, 'QuizzRes'))
                const docs = []
                const listaQ1 = []
                const listaQ2 = []
                const listaQ3 = []
                const listaQ4 = []
                const listaQ5 = []
                const listaQ6 = []
                const listaQ7 = []
                const listaQ8 = []
                const listaQ9 = []
                const total = []



                querySnapshot.forEach((doc) => {
                    const body = JSON.parse(doc.data().nombre)
                    docs.unshift(body)

                })
                docs[0].map(i => {
                    if (i.Q13 === "CURS01") {
                        listaQ1.push(parseFloat(i["Q1"].replace(/,/g, ".")))
                        listaQ2.push(parseFloat(i["Q2"].replace(/,/g, ".")))
                        listaQ3.push(parseFloat(i["Q3"].replace(/,/g, ".")))
                        listaQ4.push(parseFloat(i["Q4"].replace(/,/g, ".")))
                        listaQ5.push(parseFloat(i["Q5"].replace(/,/g, ".")))
                        listaQ6.push(parseFloat(i["Q6"].replace(/,/g, ".")))
                        listaQ7.push(parseFloat(i["Q7"].replace(/,/g, ".")))
                        listaQ8.push(parseFloat(i["Q8"].replace(/,/g, ".")))
                        listaQ9.push(parseFloat(i["Q9"].replace(/,/g, ".")))
                    }

                })
                const sumaQ1 = ((listaQ1.reduce((prev, next) => prev + next, 0)) / listaQ1.length).toFixed(2)
                const sumaQ2 = ((listaQ2.reduce((prev, next) => prev + next, 0)) / listaQ2.length).toFixed(2)
                const sumaQ3 = ((listaQ3.reduce((prev, next) => prev + next, 0)) / listaQ3.length).toFixed(2)
                const sumaQ4 = ((listaQ4.reduce((prev, next) => prev + next, 0)) / listaQ4.length).toFixed(2)
                const sumaQ5 = ((listaQ5.reduce((prev, next) => prev + next, 0)) / listaQ5.length).toFixed(2)
                const sumaQ6 = ((listaQ6.reduce((prev, next) => prev + next, 0)) / listaQ6.length).toFixed(2)
                const sumaQ7 = ((listaQ7.reduce((prev, next) => prev + next, 0)) / listaQ7.length).toFixed(2)
                const sumaQ8 = ((listaQ8.reduce((prev, next) => prev + next, 0)) / listaQ8.length).toFixed(2)
                const sumaQ9 = ((listaQ9.reduce((prev, next) => prev + next, 0)) / listaQ9.length).toFixed(2)

                total.push(sumaQ1)
                total.push(sumaQ2)
                total.push(sumaQ3)
                total.push(sumaQ4)
                total.push(sumaQ5)
                total.push(sumaQ6)
                total.push(sumaQ7)
                total.push(sumaQ8)
                total.push(sumaQ9)

                setCurs01(total)

            } catch (error) {
                console.log(error);
            }
        }
        getLista()
    }, [curs01]);

    useEffect(() => {
        const getLista = async () => {
            try {
                const querySnapshot = await getDocs(collection(db, 'QuizzRes'))
                const docs = []
                const listaQ1 = []
                const listaQ2 = []
                const listaQ3 = []
                const listaQ4 = []
                const listaQ5 = []
                const listaQ6 = []
                const listaQ7 = []
                const listaQ8 = []
                const listaQ9 = []
                const total = []



                querySnapshot.forEach((doc) => {
                    const body = JSON.parse(doc.data().nombre)
                    docs.unshift(body)

                })
                docs[0].map(i => {
                    if (i.Q13 === "CURS02") {
                        listaQ1.push(parseFloat(i["Q1"].replace(/,/g, ".")))
                        listaQ2.push(parseFloat(i["Q2"].replace(/,/g, ".")))
                        listaQ3.push(parseFloat(i["Q3"].replace(/,/g, ".")))
                        listaQ4.push(parseFloat(i["Q4"].replace(/,/g, ".")))
                        listaQ5.push(parseFloat(i["Q5"].replace(/,/g, ".")))
                        listaQ6.push(parseFloat(i["Q6"].replace(/,/g, ".")))
                        listaQ7.push(parseFloat(i["Q7"].replace(/,/g, ".")))
                        listaQ8.push(parseFloat(i["Q8"].replace(/,/g, ".")))
                        listaQ9.push(parseFloat(i["Q9"].replace(/,/g, ".")))
                    }

                })
                const sumaQ1 = ((listaQ1.reduce((prev, next) => prev + next, 0)) / listaQ1.length).toFixed(2)
                const sumaQ2 = ((listaQ2.reduce((prev, next) => prev + next, 0)) / listaQ2.length).toFixed(2)
                const sumaQ3 = ((listaQ3.reduce((prev, next) => prev + next, 0)) / listaQ3.length).toFixed(2)
                const sumaQ4 = ((listaQ4.reduce((prev, next) => prev + next, 0)) / listaQ4.length).toFixed(2)
                const sumaQ5 = ((listaQ5.reduce((prev, next) => prev + next, 0)) / listaQ5.length).toFixed(2)
                const sumaQ6 = ((listaQ6.reduce((prev, next) => prev + next, 0)) / listaQ6.length).toFixed(2)
                const sumaQ7 = ((listaQ7.reduce((prev, next) => prev + next, 0)) / listaQ7.length).toFixed(2)
                const sumaQ8 = ((listaQ8.reduce((prev, next) => prev + next, 0)) / listaQ8.length).toFixed(2)
                const sumaQ9 = ((listaQ9.reduce((prev, next) => prev + next, 0)) / listaQ9.length).toFixed(2)

                total.push(sumaQ1)
                total.push(sumaQ2)
                total.push(sumaQ3)
                total.push(sumaQ4)
                total.push(sumaQ5)
                total.push(sumaQ6)
                total.push(sumaQ7)
                total.push(sumaQ8)
                total.push(sumaQ9)

                setCurs02(total)

            } catch (error) {
                console.log(error);
            }
        }
        getLista()
    }, [curs02]);

    useEffect(() => {
        const getLista = async () => {
            try {
                const querySnapshot = await getDocs(collection(db, 'QuizzRes'))
                const docs = []
                const listaQ1 = []
                const listaQ2 = []
                const listaQ3 = []
                const listaQ4 = []
                const listaQ5 = []
                const listaQ6 = []
                const listaQ7 = []
                const listaQ8 = []
                const listaQ9 = []
                const total = []



                querySnapshot.forEach((doc) => {
                    const body = JSON.parse(doc.data().nombre)
                    docs.unshift(body)

                })
                docs[0].map(i => {
                    if (i.Q13 === "CURS03") {
                        listaQ1.push(parseFloat(i["Q1"].replace(/,/g, ".")))
                        listaQ2.push(parseFloat(i["Q2"].replace(/,/g, ".")))
                        listaQ3.push(parseFloat(i["Q3"].replace(/,/g, ".")))
                        listaQ4.push(parseFloat(i["Q4"].replace(/,/g, ".")))
                        listaQ5.push(parseFloat(i["Q5"].replace(/,/g, ".")))
                        listaQ6.push(parseFloat(i["Q6"].replace(/,/g, ".")))
                        listaQ7.push(parseFloat(i["Q7"].replace(/,/g, ".")))
                        listaQ8.push(parseFloat(i["Q8"].replace(/,/g, ".")))
                        listaQ9.push(parseFloat(i["Q9"].replace(/,/g, ".")))
                    }

                })
                const sumaQ1 = ((listaQ1.reduce((prev, next) => prev + next, 0)) / listaQ1.length).toFixed(2)
                const sumaQ2 = ((listaQ2.reduce((prev, next) => prev + next, 0)) / listaQ2.length).toFixed(2)
                const sumaQ3 = ((listaQ3.reduce((prev, next) => prev + next, 0)) / listaQ3.length).toFixed(2)
                const sumaQ4 = ((listaQ4.reduce((prev, next) => prev + next, 0)) / listaQ4.length).toFixed(2)
                const sumaQ5 = ((listaQ5.reduce((prev, next) => prev + next, 0)) / listaQ5.length).toFixed(2)
                const sumaQ6 = ((listaQ6.reduce((prev, next) => prev + next, 0)) / listaQ6.length).toFixed(2)
                const sumaQ7 = ((listaQ7.reduce((prev, next) => prev + next, 0)) / listaQ7.length).toFixed(2)
                const sumaQ8 = ((listaQ8.reduce((prev, next) => prev + next, 0)) / listaQ8.length).toFixed(2)
                const sumaQ9 = ((listaQ9.reduce((prev, next) => prev + next, 0)) / listaQ9.length).toFixed(2)

                total.push(sumaQ1)
                total.push(sumaQ2)
                total.push(sumaQ3)
                total.push(sumaQ4)
                total.push(sumaQ5)
                total.push(sumaQ6)
                total.push(sumaQ7)
                total.push(sumaQ8)
                total.push(sumaQ9)

                setCurs03(total)

            } catch (error) {
                console.log(error);
            }
        }
        getLista()
    }, [curs03]);

    useEffect(() => {
        const getLista = async () => {
            try {
                const querySnapshot = await getDocs(collection(db, 'QuizzRes'))
                const docs = []
                const listaQ1 = []
                const listaQ2 = []
                const listaQ3 = []
                const listaQ4 = []
                const listaQ5 = []
                const listaQ6 = []
                const listaQ7 = []
                const listaQ8 = []
                const listaQ9 = []
                const total = []



                querySnapshot.forEach((doc) => {
                    const body = JSON.parse(doc.data().nombre)
                    docs.unshift(body)

                })
                docs[0].map(i => {
                    if (i.Q13 === "CURS03" || i.Q13 === "CURS02" || i.Q13 === "CURS01") {
                        listaQ1.push(parseFloat(i["Q1"].replace(/,/g, ".")))
                        listaQ2.push(parseFloat(i["Q2"].replace(/,/g, ".")))
                        listaQ3.push(parseFloat(i["Q3"].replace(/,/g, ".")))
                        listaQ4.push(parseFloat(i["Q4"].replace(/,/g, ".")))
                        listaQ5.push(parseFloat(i["Q5"].replace(/,/g, ".")))
                        listaQ6.push(parseFloat(i["Q6"].replace(/,/g, ".")))
                        listaQ7.push(parseFloat(i["Q7"].replace(/,/g, ".")))
                        listaQ8.push(parseFloat(i["Q8"].replace(/,/g, ".")))
                        listaQ9.push(parseFloat(i["Q9"].replace(/,/g, ".")))
                    }

                })
                const sumaQ1 = ((listaQ1.reduce((prev, next) => prev + next, 0)) / listaQ1.length).toFixed(2)
                const sumaQ2 = ((listaQ2.reduce((prev, next) => prev + next, 0)) / listaQ2.length).toFixed(2)
                const sumaQ3 = ((listaQ3.reduce((prev, next) => prev + next, 0)) / listaQ3.length).toFixed(2)
                const sumaQ4 = ((listaQ4.reduce((prev, next) => prev + next, 0)) / listaQ4.length).toFixed(2)
                const sumaQ5 = ((listaQ5.reduce((prev, next) => prev + next, 0)) / listaQ5.length).toFixed(2)
                const sumaQ6 = ((listaQ6.reduce((prev, next) => prev + next, 0)) / listaQ6.length).toFixed(2)
                const sumaQ7 = ((listaQ7.reduce((prev, next) => prev + next, 0)) / listaQ7.length).toFixed(2)
                const sumaQ8 = ((listaQ8.reduce((prev, next) => prev + next, 0)) / listaQ8.length).toFixed(2)
                const sumaQ9 = ((listaQ9.reduce((prev, next) => prev + next, 0)) / listaQ9.length).toFixed(2)

                total.push(sumaQ1)
                total.push(sumaQ2)
                total.push(sumaQ3)
                total.push(sumaQ4)
                total.push(sumaQ5)
                total.push(sumaQ6)
                total.push(sumaQ7)
                total.push(sumaQ8)
                total.push(sumaQ9)

                setGrau(total)

            } catch (error) {
                console.log(error);
            }
        }
        getLista()
    }, [grau]);


    useEffect(() => {
        const getLista = async () => {
            try {
                const preRef = collection(db, 'QuizzPreguntes')
                const q = await getDocs(preRef)
                const docs = []

                q.forEach((doc) => {
                    docs.unshift(doc.data())
                })

                setPreguntes(docs);


            } catch (error) {
                console.log(error);
            }
        }
        getLista()
    }, [preguntes]);

    /*if (loading) {
        return (
            <Loading />
        )
    }
    else {*/


        if (admin || admin2) return (
            <>
                <Container className="mt-4">
                    <Row>
                        <Col lg={8} className="d-flex hidden-700-max">
                            <div className="align-self-center ps-2 pe-2 "><img src={logo} alt="ESCAC" width="400" className="img-fluid" /></div>
                        </Col>

                        {/* PHONE VERSION */}
                        <Col className="d-flex justify-content-center">
                            <div className="ps-2 pe-2 hidden-700-min align-self-center"><img src={logoMin} alt="ESCAC" width="150" className="img-fluid" /></div>
                        </Col>
                        {/* =============== */}

                        <Col lg={3} className="mt-1 ">

                            <Row className="m-0 p-0 ">
                                <Col lg={3} md={2} className="m-0 hidden-700-max">

                                    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" className="bi bi-person-circle " viewBox="0 0 16 16">
                                        <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                                        <path fillRule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z" />
                                    </svg>

                                </Col>
                                <Col lg={9} md={10} className="pe-0 border-start border-dark border-2">
                                    <Row className="m-0 p-0">
                                        <Col lg={11} md={11} className="p-0 fs-6">{nombre}</Col>

                                    </Row>
                                    <Row>
                                        <Col className="mt-1">
                                            <a target="_blank" href="https://outlook.office.com/mail/">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-envelope pb-1" viewBox="0 0 16 16">
                                                    <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z" />
                                                </svg></a>
                                            &nbsp; <a target="_blank" href="https://outlook.office.com/mail/">Bústia</a>
                                        </Col>
                                        <Col lg={3} md={1} className="p-0 pe-2 pt-1">
                                            <a href="" onClick={handleLogout}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#00000" className="bi bi-arrow-bar-right" viewBox="0 0 16 16">
                                                    <path fillRule="evenodd" d="M6 8a.5.5 0 0 0 .5.5h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L12.293 7.5H6.5A.5.5 0 0 0 6 8Zm-2.5 7a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 1 0v13a.5.5 0 0 1-.5.5Z" />
                                                </svg>
                                            </a>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>

                    </Row>
                   
                    <QuizzProfe />

                </Container>
            </>)
        if (valido) return (
            <>
                <Container className="mt-4">
                    <Row>
                        <Col lg={8} className="d-flex hidden-700-max">
                            <div className="align-self-center ps-2 pe-2 "><img src={logo} alt="ESCAC" width="400" className="img-fluid" /></div>
                        </Col>

                        {/* PHONE VERSION */}
                        <Col className="d-flex justify-content-center">
                            <div className="ps-2 pe-2 hidden-700-min align-self-center"><img src={logoMin} alt="ESCAC" width="150" className="img-fluid" /></div>
                        </Col>
                        {/* =============== */}

                        <Col lg={3} className="mt-1 ">

                            <Row className="m-0 p-0 ">
                                <Col lg={3} md={2} className="m-0 hidden-700-max">

                                    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" className="bi bi-person-circle " viewBox="0 0 16 16">
                                        <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                                        <path fillRule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z" />
                                    </svg>

                                </Col>
                                <Col lg={9} md={10} className="pe-0 border-start border-dark border-2">
                                    <Row className="m-0 p-0">
                                        <Col lg={11} md={11} className="p-0 fs-6">{nombre}</Col>

                                    </Row>
                                    <Row>
                                        <Col className="mt-1">
                                            <a target="_blank" href="https://outlook.office.com/mail/">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="#000000" className="bi bi-envelope pb-1" viewBox="0 0 16 16">
                                                    <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z" />
                                                </svg></a>
                                            &nbsp; <a target="_blank" href="https://outlook.office.com/mail/">Bústia</a>
                                        </Col>
                                        <Col lg={3} md={1} className="p-0 pe-2 pt-1">
                                            <a href="" onClick={handleLogout}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#00000" className="bi bi-arrow-bar-right" viewBox="0 0 16 16">
                                                    <path fillRule="evenodd" d="M6 8a.5.5 0 0 0 .5.5h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L12.293 7.5H6.5A.5.5 0 0 0 6 8Zm-2.5 7a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 1 0v13a.5.5 0 0 1-.5.5Z" />
                                                </svg>
                                            </a>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>

                    </Row>


                    <div className="mt-0 mt-lg-4 p-3 p-lg-0 p-md-0 p-sm-0 mt-sm-2 border-bottom border-2 border-dark mb-4">
                        <h2 className="m-0 fw-bold ">Resultats enquestes</h2>
                    </div>
                    {
                        lista1.map(x => (
                            x.map((i, index1) => {
                                if (i.Q10 === email && i.Q11 === "master")
                                    return (
                                        <Accordion defaultActiveKey="0" key={index1} className="mb-3">
                                            <Accordion.Item eventKey={index1}>
                                                <Accordion.Header><Row>
                                                    <Col lg={12}><b>{i.Asignatura}</b></Col>
                                                    <Col lg={6}>{i.Q13}</Col>
                                                </Row></Accordion.Header>


                                                <Accordion.Body>
                                                    <Row className="border-bottom mb-2 pb-2">
                                                        <Col lg={8}>{preguntes[0].Q1}</Col>
                                                        <Col>{i.Q1}</Col>
                                                    </Row>
                                                    <Row className="border-bottom mb-2 pb-2">
                                                        <Col lg={8}>{preguntes[0].Q2}</Col>
                                                        <Col>{i.Q2}</Col>
                                                    </Row>
                                                    <Row className="border-bottom mb-2 pb-2">
                                                        <Col lg={8}>{preguntes[0].Q3}</Col>
                                                        <Col>{i.Q3}</Col>
                                                    </Row>
                                                    <Row className="border-bottom mb-2 pb-2">
                                                        <Col lg={8}>{preguntes[0].Q4}</Col>
                                                        <Col>{i.Q4}</Col>
                                                    </Row>
                                                    <Row className="border-bottom mb-2 pb-2">
                                                        <Col lg={8}>{preguntes[0].Q5}</Col>
                                                        <Col>{i.Q5}</Col>
                                                    </Row>
                                                    <Row className="border-bottom mb-2 pb-2">
                                                        <Col lg={8}>{preguntes[0].Q6}</Col>
                                                        <Col>{i.Q6}</Col>
                                                    </Row>
                                                    <Row className="border-bottom mb-2 pb-2">
                                                        <Col lg={8}>{preguntes[0].Q7}</Col>
                                                        <Col>{i.Q7}</Col>
                                                    </Row>
                                                    <Row className="border-bottom mb-2 pb-2">
                                                        <Col lg={8}>{preguntes[0].Q8}</Col>
                                                        <Col>{i.Q8}</Col>
                                                    </Row>
                                                </Accordion.Body>

                                            </Accordion.Item>
                                        </Accordion>

                                    );
                                if (i.Q10 === email && i.Q13 === "CURS01" && i.Q11 === "grau")
                                    return (
                                        <Accordion defaultActiveKey="0" key={index1} className="mb-3">
                                            <Accordion.Item eventKey={index1}>
                                                <Accordion.Header>
                                                    <Row>
                                                        <Col lg={12}><b>{i.Asignatura}</b></Col>
                                                        <Col lg={6}>{i.Q13}</Col>
                                                    </Row>
                                                </Accordion.Header>


                                                <Accordion.Body>
                                                    <Row className="mb-5 pb-2 mt-4">
                                                        <Col className="text-center"><b>Nº ALUMNES</b> <Row><Col className="text-center h5">{i.Q16}</Col></Row></Col>
                                                        <Col className="text-center"><b>Nº ENQUESTES</b> <Row><Col className="text-cente h5">{i.Q15}</Col></Row></Col>
                                                        <Col className="text-center">
                                                            <Row className=" text-center ">
                                                                <Col className="text-end "><b>PARTICIPACIÓ</b></Col>
                                                                <Col className="text-start" >
                                                                    <CircularProgressbar className=" w-50" value={((i.Q15 * 100) / i.Q16).toFixed(2)} text={((i.Q15 * 100) / i.Q16).toFixed(2) + '%'}
                                                                        styles={buildStyles({
                                                                            pathColor: '#121212',
                                                                            textColor: '#121212',
                                                                            pathTransitionDuration: 0.5
                                                                        })} />
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                    <Row className="border-bottom mb-2 pb-2">
                                                        <Col lg={8}></Col>
                                                        <Col className="text-center"><b>PROFESSOR</b></Col>
                                                        <Col className="text-center"><b>CURS</b></Col>
                                                        <Col className="text-center"><b>GRAU</b></Col>
                                                    </Row>
                                                    <Row className="border-bottom mb-2 pb-2">
                                                        <Col lg={8}>{preguntes[1].Q1}</Col>
                                                        <Col className="text-center">{i.Q1}</Col>
                                                        <Col className="text-center">{curs01[0]}</Col>
                                                        <Col className="text-center">{grau[0]}</Col>
                                                    </Row>
                                                    <Row className="border-bottom mb-2 pb-2">
                                                        <Col lg={8}>{preguntes[1].Q2}</Col>
                                                        <Col className="text-center">{i.Q2}</Col>
                                                        <Col className="text-center">{curs01[1]}</Col>
                                                        <Col className="text-center">{grau[1]}</Col>
                                                    </Row>
                                                    <Row className="border-bottom mb-2 pb-2">
                                                        <Col lg={8}>{preguntes[1].Q3}</Col>
                                                        <Col className="text-center">{i.Q3}</Col>
                                                        <Col className="text-center">{curs01[2]}</Col>
                                                        <Col className="text-center">{grau[2]}</Col>
                                                    </Row>
                                                    <Row className="border-bottom mb-2 pb-2">
                                                        <Col lg={8}>{preguntes[1].Q4}</Col>
                                                        <Col className="text-center">{i.Q4}</Col>
                                                        <Col className="text-center">{curs01[3]}</Col>
                                                        <Col className="text-center">{grau[3]}</Col>
                                                    </Row>
                                                    <Row className="border-bottom mb-2 pb-2">
                                                        <Col lg={8}>{preguntes[1].Q5}</Col>
                                                        <Col className="text-center">{i.Q5}</Col>
                                                        <Col className="text-center">{curs01[4]}</Col>
                                                        <Col className="text-center">{grau[4]}</Col>
                                                    </Row>
                                                    <Row className="border-bottom mb-2 pb-2">
                                                        <Col lg={8}>{preguntes[1].Q6}</Col>
                                                        <Col className="text-center">{i.Q6}</Col>
                                                        <Col className="text-center">{curs01[5]}</Col>
                                                        <Col className="text-center">{grau[5]}</Col>
                                                    </Row>
                                                    <Row className="border-bottom mb-2 pb-2">
                                                        <Col lg={8}>{preguntes[1].Q7}</Col>
                                                        <Col className="text-center">{i.Q7}</Col>
                                                        <Col className="text-center">{curs01[6]}</Col>
                                                        <Col className="text-center">{grau[6]}</Col>
                                                    </Row>
                                                    <Row className="border-bottom mb-2 pb-2">
                                                        <Col lg={8}>{preguntes[1].Q8}</Col>
                                                        <Col className="text-center">{i.Q8}</Col>
                                                        <Col className="text-center">{curs01[7]}</Col>
                                                        <Col className="text-center">{grau[7]}</Col>
                                                    </Row>
                                                    <Row className="border-bottom mb-2 pb-2">
                                                        <Col lg={8}>{preguntes[1].Q9}</Col>
                                                        <Col className="text-center">{i.Q9}</Col>
                                                        <Col className="text-center">{curs01[8]}</Col>
                                                        <Col className="text-center">{grau[8]}</Col>
                                                    </Row>
                                                    <Row className="mb-4 pb-2 mt-4">
                                                        <Col lg={8}>VALORACIÓ PROFESSOR</Col>
                                                        <Col className="text-center"><span className="border  border-dark  p-3 pt-3 pb-3">{i.Q12}</span></Col>
                                                    </Row>
                                                </Accordion.Body>

                                            </Accordion.Item>
                                        </Accordion>
                                    );
                                if (i.Q10 === email && i.Q13 === "CURS02" && i.Q11 === "grau")
                                    return (
                                        <Accordion defaultActiveKey="0" key={index1} className="mb-3">
                                            <Accordion.Item eventKey={index1}>
                                                <Accordion.Header>
                                                    <Row>
                                                        <Col lg={12}><b>{i.Asignatura}</b></Col>
                                                        <Col lg={6}>{i.Q13}</Col>
                                                    </Row>
                                                </Accordion.Header>


                                                <Accordion.Body>
                                                    <Row className="mb-5 pb-2 mt-4">
                                                        <Col className="text-center"><b>Nº ALUMNES</b> <Row><Col className="text-center h5">{i.Q16}</Col></Row></Col>
                                                        <Col className="text-center"><b>Nº ENQUESTES</b> <Row><Col className="text-cente h5">{i.Q15}</Col></Row></Col>
                                                        <Col className="text-center">
                                                            <Row className=" text-center ">
                                                                <Col className="text-end "><b>PARTICIPACIÓ</b></Col>
                                                                <Col className="text-start" >
                                                                    <CircularProgressbar className=" w-50" value={((i.Q15 * 100) / i.Q16).toFixed(2)} text={((i.Q15 * 100) / i.Q16).toFixed(2) + '%'}
                                                                        styles={buildStyles({
                                                                            pathColor: '#121212',
                                                                            textColor: '#121212',
                                                                            pathTransitionDuration: 0.5
                                                                        })} />
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                    <Row className="border-bottom mb-2 pb-2">
                                                        <Col lg={8}></Col>
                                                        <Col className="text-center"><b>PROFESSOR</b></Col>
                                                        <Col className="text-center"><b>CURS</b></Col>
                                                        <Col className="text-center"><b>GRAU</b></Col>
                                                    </Row>
                                                    <Row className="border-bottom mb-2 pb-2">
                                                        <Col lg={8}>{preguntes[1].Q1}</Col>
                                                        <Col className="text-center">{i.Q1}</Col>
                                                        <Col className="text-center">{curs02[0]}</Col>
                                                        <Col className="text-center">{grau[0]}</Col>
                                                    </Row>
                                                    <Row className="border-bottom mb-2 pb-2">
                                                        <Col lg={8}>{preguntes[1].Q2}</Col>
                                                        <Col className="text-center">{i.Q2}</Col>
                                                        <Col className="text-center">{curs02[1]}</Col>
                                                        <Col className="text-center">{grau[1]}</Col>
                                                    </Row>
                                                    <Row className="border-bottom mb-2 pb-2">
                                                        <Col lg={8}>{preguntes[1].Q3}</Col>
                                                        <Col className="text-center">{i.Q3}</Col>
                                                        <Col className="text-center">{curs02[2]}</Col>
                                                        <Col className="text-center">{grau[2]}</Col>
                                                    </Row>
                                                    <Row className="border-bottom mb-2 pb-2">
                                                        <Col lg={8}>{preguntes[1].Q4}</Col>
                                                        <Col className="text-center">{i.Q4}</Col>
                                                        <Col className="text-center">{curs02[3]}</Col>
                                                        <Col className="text-center">{grau[3]}</Col>
                                                    </Row>
                                                    <Row className="border-bottom mb-2 pb-2">
                                                        <Col lg={8}>{preguntes[1].Q5}</Col>
                                                        <Col className="text-center">{i.Q5}</Col>
                                                        <Col className="text-center">{curs02[4]}</Col>
                                                        <Col className="text-center">{grau[4]}</Col>
                                                    </Row>
                                                    <Row className="border-bottom mb-2 pb-2">
                                                        <Col lg={8}>{preguntes[1].Q6}</Col>
                                                        <Col className="text-center">{i.Q6}</Col>
                                                        <Col className="text-center">{curs02[5]}</Col>
                                                        <Col className="text-center">{grau[5]}</Col>
                                                    </Row>
                                                    <Row className="border-bottom mb-2 pb-2">
                                                        <Col lg={8}>{preguntes[1].Q7}</Col>
                                                        <Col className="text-center">{i.Q7}</Col>
                                                        <Col className="text-center">{curs02[6]}</Col>
                                                        <Col className="text-center">{grau[6]}</Col>
                                                    </Row>
                                                    <Row className="border-bottom mb-2 pb-2">
                                                        <Col lg={8}>{preguntes[1].Q8}</Col>
                                                        <Col className="text-center">{i.Q8}</Col>
                                                        <Col className="text-center">{curs02[7]}</Col>
                                                        <Col className="text-center">{grau[7]}</Col>
                                                    </Row>
                                                    <Row className="border-bottom mb-2 pb-2">
                                                        <Col lg={8}>{preguntes[1].Q9}</Col>
                                                        <Col className="text-center">{i.Q9}</Col>
                                                        <Col className="text-center">{curs02[8]}</Col>
                                                        <Col className="text-center">{grau[8]}</Col>
                                                    </Row>
                                                    <Row className="mb-4 pb-2 mt-4">
                                                        <Col lg={8}>VALORACIÓ PROFESSOR</Col>
                                                        <Col className="text-center"><span className="border  border-dark  p-3 pt-3 pb-3">{i.Q12}</span></Col>

                                                    </Row>
                                                </Accordion.Body>

                                            </Accordion.Item>
                                        </Accordion>
                                    );
                                if (i.Q10 === email && i.Q13 === "CURS03" && i.Q11 === "grau")
                                    return (
                                        <Accordion defaultActiveKey="0" key={index1} className="mb-3">
                                        <Accordion.Item eventKey={index1}>
                                            <Accordion.Header>
                                                <Row>
                                                    <Col lg={12}><b>{i.Asignatura}</b></Col>
                                                    <Col lg={6}>{i.Q13}</Col>
                                                </Row>
                                            </Accordion.Header>


                                            <Accordion.Body>
                                                <Row className="mb-5 pb-2 mt-4">
                                                    <Col className="text-center"><b>Nº ALUMNES</b> <Row><Col className="text-center h5">{i.Q16}</Col></Row></Col>
                                                    <Col className="text-center"><b>Nº ENQUESTES</b> <Row><Col className="text-cente h5">{i.Q15}</Col></Row></Col>
                                                    <Col className="text-center">
                                                        <Row className=" text-center ">
                                                            <Col className="text-end "><b>PARTICIPACIÓ</b></Col>
                                                            <Col className="text-start" >
                                                                <CircularProgressbar className=" w-50" value={((i.Q15 * 100) / i.Q16).toFixed(2)} text={((i.Q15 * 100) / i.Q16).toFixed(2) + '%'}
                                                                    styles={buildStyles({
                                                                        pathColor: '#121212',
                                                                        textColor: '#121212',
                                                                        pathTransitionDuration: 0.5
                                                                    })} />
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                                <Row className="border-bottom mb-2 pb-2">
                                                    <Col lg={8}></Col>
                                                    <Col className="text-center"><b>PROFESSOR</b></Col>
                                                    <Col className="text-center"><b>CURS</b></Col>
                                                    <Col className="text-center"><b>GRAU</b></Col>
                                                </Row>
                                                <Row className="border-bottom mb-2 pb-2">
                                                    <Col lg={8}>{preguntes[1].Q1}</Col>
                                                    <Col className="text-center">{i.Q1}</Col>
                                                    <Col className="text-center">{curs03[0]}</Col>
                                                    <Col className="text-center">{grau[0]}</Col>
                                                </Row>
                                                <Row className="border-bottom mb-2 pb-2">
                                                    <Col lg={8}>{preguntes[1].Q2}</Col>
                                                    <Col className="text-center">{i.Q2}</Col>
                                                    <Col className="text-center">{curs03[1]}</Col>
                                                    <Col className="text-center">{grau[1]}</Col>
                                                </Row>
                                                <Row className="border-bottom mb-2 pb-2">
                                                    <Col lg={8}>{preguntes[1].Q3}</Col>
                                                    <Col className="text-center">{i.Q3}</Col>
                                                    <Col className="text-center">{curs03[2]}</Col>
                                                    <Col className="text-center">{grau[2]}</Col>
                                                </Row>
                                                <Row className="border-bottom mb-2 pb-2">
                                                    <Col lg={8}>{preguntes[1].Q4}</Col>
                                                    <Col className="text-center">{i.Q4}</Col>
                                                    <Col className="text-center">{curs03[3]}</Col>
                                                    <Col className="text-center">{grau[3]}</Col>
                                                </Row>
                                                <Row className="border-bottom mb-2 pb-2">
                                                    <Col lg={8}>{preguntes[1].Q5}</Col>
                                                    <Col className="text-center">{i.Q5}</Col>
                                                    <Col className="text-center">{curs03[4]}</Col>
                                                    <Col className="text-center">{grau[4]}</Col>
                                                </Row>
                                                <Row className="border-bottom mb-2 pb-2">
                                                    <Col lg={8}>{preguntes[1].Q6}</Col>
                                                    <Col className="text-center">{i.Q6}</Col>
                                                    <Col className="text-center">{curs03[5]}</Col>
                                                    <Col className="text-center">{grau[5]}</Col>
                                                </Row>
                                                <Row className="border-bottom mb-2 pb-2">
                                                    <Col lg={8}>{preguntes[1].Q7}</Col>
                                                    <Col className="text-center">{i.Q7}</Col>
                                                    <Col className="text-center">{curs03[6]}</Col>
                                                    <Col className="text-center">{grau[6]}</Col>
                                                </Row>
                                                <Row className="border-bottom mb-2 pb-2">
                                                    <Col lg={8}>{preguntes[1].Q8}</Col>
                                                    <Col className="text-center">{i.Q8}</Col>
                                                    <Col className="text-center">{curs03[7]}</Col>
                                                    <Col className="text-center">{grau[7]}</Col>
                                                </Row>
                                                <Row className="border-bottom mb-2 pb-2">
                                                    <Col lg={8}>{preguntes[1].Q9}</Col>
                                                    <Col className="text-center">{i.Q9}</Col>
                                                    <Col className="text-center">{curs03[8]}</Col>
                                                    <Col className="text-center">{grau[8]}</Col>
                                                </Row>
                                                <Row className="mb-4 pb-2 mt-4">
                                                    <Col lg={8}>VALORACIÓ PROFESSOR</Col>
                                                    <Col className="text-center"><span className="border  border-dark  p-3 pt-3 pb-3">{i.Q12}</span></Col>
                                                </Row>
                                            </Accordion.Body>

                                        </Accordion.Item>
                                    </Accordion>
                                    );

                            })
                        ))}
                </Container>
            </>
        )

    

}

export default Resultats